import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyAcAIpEFWAMwPUMSv8xgQvj2l8ObqKIcFY",
    authDomain: "disaster-rapid-response.firebaseapp.com",
    projectId: "disaster-rapid-response",
    storageBucket: "disaster-rapid-response.appspot.com",
    messagingSenderId: "206956154263",
    appId: "1:206956154263:web:ac724379094a456fd108d2"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

export default app;